import request from '@/utils/request'
import interfaceHandle from '@/utils/index'

export function wxtreatstatistics (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatstatistics', data, true)
    })
}

export function wxtreatqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatqry', data, true)
    })
}

export function wxtreatdetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatdetail', data, true)
    })
}

export function wxtreatopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatopr', data, true)
    })
}

export function wxdoctorqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdoctorqry', data, true)
    })
}

export function wxroomqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxroomqry', data, true)
    })
}

export function wxopenhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxopenhistoryqry', data, true)
    })
}

export function wxtreathistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreathistoryqry', data, true)
    })
}

export function wxsurplusqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsurplusqry', data, true)
    })
}

export function wxjsapiauth (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxjsapiauth', data, true)
    })
}

export function wxmedicalhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicalhistoryqry', data, true)
    })
}

export function wxmedicaldetailqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicaldetailqry', data, true)
    })
}

export function wxseestatistics (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxseestatistics', data, true)
    })
}

export function wxseeqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxseeqry', data, true)
    })
}

export function wxsendpreinfo (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsendpreinfo', data, true)
    })
}

export function wxdeleteattach (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdeleteattach', data, true)
    })
}


export function wxattachqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxattachqry', data, true)
    })
}

export function wxcheckhistoryqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxcheckhistoryqry', data, true)
    })
}

export function wxinfraredreportqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxinfraredreportqry', data, true)
    })
}


export function wxhabitusreportqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxhabitusreportqry', data, true)
    })
}

export function wxacupointqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxacupointqry', data, true)
    })
}

export function wxaddacupoint (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxaddacupoint', data, true)
    })
}


export function wxacupointopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxacupointopr', data, true)
    })
}

export function wxsavesignature (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsavesignature', data, true)
    })
}

export function waitsignaturelist (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('waitsignaturelist', data, true)
    })
}
export function wxprojectlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxprojectlistqry', data, true)
    })
}
export function wxtriageprojectqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageprojectqry', data, true)
    })
}
export function wxtreattriageopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreattriageopr', data, true)
    })
}
export function curuserqrytreattriage (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('curuserqrytreattriage', data, true)
    })
}
export function wxtriagedoctorroomqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagedoctorroomqry', data, true)
    })
}
export function wxtreatuserphonecaptchano (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatuserphonecaptchano', data, true)
    })
}
export function wxtriagecustomerqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecustomerqry', data, true)
    })
}
// 
export function wxtriagecurdayappointmentlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecurdayappointmentlistqry', data, true)
    })
}
export function wxtriageappointmentlistqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageappointmentlistqry', data, true)
    })
}
export function wxtriagecouponnum (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecouponnum', data, true)
    })
}
export function wxtriagecouponqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecouponqry', data, true)
    })
}
export function wxtriagecardnum (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecardnum', data, true)
    })
}
export function wxtriagecardqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagecardqry', data, true)
    })
}
export function wxtriagedictionarydataqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagedictionarydataqry', data, true)
    })
}
export function wxtreatregisteredmember (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtreatregisteredmember', data, true)
    })
}
export function wxtriageeditcardreceive (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageeditcardreceive', data, true)
    })
}
export function wxtriagememberinfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriagememberinfoqry', data, true)
    })
}
export function wxtriageworkbenchqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtriageworkbenchqry', data, true)
    })
}
export function wxappointmentplaninfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplaninfoqry', data, true)
    })
  }
  export function wxappointmentdatebydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentdatebydoctor', data, true)
    })
  }
  export function   wxappointmentplandetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplandetail', data, true)
    })
  }
  export function   wxconfirmappointment (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxconfirmappointment', data, true)
    })
  }
  export function   wxappointmentcheckininfoqry (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentcheckininfoqry', data, true)
    })
  }
  export function wxappointmentopr (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentopr', data, true)
    })
  }
  export function wxappointmenttypebydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmenttypebydoctor', data, true)
    })
  }
  export function wxappointmentdetail (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentdetail', data, true)
    })
  }
  export function wxappointmentplanbydoctor (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxappointmentplanbydoctor', data, true)
    })
  }
  export function wxsubmitappointmentinfo (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsubmitappointmentinfo', data, true)
    })
  }
  export function  wxnoappointmentcustomerinfoqry  (data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxnoappointmentcustomerinfoqry', data, true)
    })
  }  
  
  export function  wxdispenselist(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdispenselist', data, true)
    })
  }
  export function  wxdispensedetail(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdispensedetail', data, true)
    })
  }
  export function  getwxqryclinic(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryclinic', data, true)
    })
  }
  export function  wxdispensetypecount(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdispensetypecount', data, true)
    })
  }
  export function  wxmodifypreparation(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmodifypreparation', data, true)
    })
  }
  export function  wxtakedosage(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtakedosage', data, true)
    })
  }
  export function  wxdispensing(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxdispensing', data, true)
    })
  }
  export function  wxaddressqry(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxaddressqry', data, true)
    })
  }
  export function  wxmodifyexpress(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmodifyexpress', data, true)
    })
  }
  export function  wxaddaddress(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxaddaddress', data, true)
    })
  }
  export function  wxareaqry(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxareaqry', data, true)
    })
  }
  export function  wxexpressmatchinfoqry(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxexpressmatchinfoqry', data, true)
    })
  }
  
export function wxclinicfayaodoctorlist(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxclinicfayaodoctorlist', data, true)
    })
}

/**
 * 3.82.处方详情
 * @param {*} data 
 * @returns 
 */
export function wxprescriptiondetail(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxprescriptiondetail', data, true)
    })
}

/**
 * 3.83.体温图详情
 * @param {*} data 
 * @returns 
 */
export function wxtemper(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxtemper', data, true)
    })
}

/**
 * 3.84.血糖图详情
 * @param {*} data 
 * @returns 
 */
export function wxbloodglucose(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxbloodglucose', data, true)
    })
}

/**
 * 3.85.血压图详情
 * @param {*} data 
 * @returns 
 */
export function wxbloodpressure(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxbloodpressure', data, true)
    })
}

/**
 * 3.86.红外影像详情
 * @param {*} data 
 * @returns 
 */
export function wxinfraredimage(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxinfraredimage', data, true)
    })
}

/**
 * 3.87.病历总数查询
 * @param {*} data 
 * @returns 
 */
export function wxmedicalhistorycount(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicalhistorycount', data, true)
    })
}

/**
 * 3.88.按姓名查询病历
 * @param {*} data 
 * @returns 
 */
export function wxmedicalhistoryqrybyname(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxmedicalhistoryqrybyname', data, true)
    })
}

/**
 * 3.89.查询短语信息
 * @param {*} data 
 * @returns 
 */
export function wxqrycommonphrases(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqrycommonphrases', data, true)
    })
}

/**
 * 3.90.处方日期查询
 * @param {*} data 
 * @returns 
 */
export function wxqryprescriptiondate(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryprescriptiondate', data, true)
    })
}

/**
 * 3.91.查询历史处方
 * @param {*} data 
 * @returns 
 */
export function wxqryhistoryprescription(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryhistoryprescription', data, true)
    })
}

/**
 * 3.92.查询处方模板
 * @param {*} data 
 * @returns 
 */
export function wxqryprescriptiontemplate(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryprescriptiontemplate', data, true)
    })
}

/**
 * 3.93.查询药品
 * @param {*} data 
 * @returns 
 */
export function wxqrymedicaldrug(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqrymedicaldrug', data, true)
    })
}

/**
 * 3.94.制作费查询
 * @param {*} data 
 * @returns 
 */
export function wxqryproductionfee(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryproductionfee', data, true)
    })
}

/**
 * 3.95.查询处方模板详情
 * @param {*} data 
 * @returns 
 */
export function wxqryprescriptiontemplatedetail(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryprescriptiontemplatedetail', data, true)
    })
}

/**
 * 3.96.提交处方信息
 * @param {*} data 
 * @returns 
 */
export function wxsubmitprescription(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxsubmitprescription', data, true)
    })
}

/**
 * 3.97.修改预约状态
 * @param {*} data 
 * @returns 
 */
export function wxchangeappointrecordstatus(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxchangeappointrecordstatus', data, true)
    })
}

/**
 * 3.98.查询未付款订单
 * @param {*} data 
 * @returns 
 */
export function wxqryprescriptionnopaynum(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxqryprescriptionnopaynum', data, true)
    })
}

/**
 * 3.99.完成接诊
 * @param {*} data 
 * @returns 
 */
export function wxfinishtreat(data) {
    return request({
        url: '',
        method: 'post',
        data: interfaceHandle('wxfinishtreat', data, true)
    })
}